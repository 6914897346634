import store from '@/store'

export const contentTableConfig = {
  title: '',
  propList: [
    {
      prop: 'name',
      label: 'general.name',
      width: 180
    },
    {
      prop: 'tenantId',
      label: 'user.tenant',
      width: 180,
      formatter: (row) => {
        return row ? store.getters.tenantDataMap[row] : ''
      }
    },
    {
      prop: 'description',
      label: 'general.description'
    },
    {
      prop: 'createdTime',
      slotName: 'formatZeroTime',
      label: 'common.app-creation-time',
      width: 160
    },
    {
      label: 'common.app-operation',
      width: '280',
      slotName: 'handler'
    }
  ],
  showIndexColumn: true
}
